export enum CampaignStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export namespace CampaignStatus {
  export function getAllCampaignStatuses(): CampaignStatus[] {
    return [CampaignStatus.ACTIVE, CampaignStatus.INACTIVE];
  }

  export function getDisplayName(status?: CampaignStatus): string {
    switch (status) {
      case CampaignStatus.ACTIVE:
        return 'APP.ACTIVE';
      case CampaignStatus.INACTIVE:
        return 'APP.INACTIVE';
      default:
        return '';
    }
  }
}
